import React from 'react'
import Map from 'google-map-react'
import { Box } from '@chakra-ui/react'

const defaultProps = {
  center: {
    lat: 40.17516942299981,
    lng: 44.50979995368238,
  },
  mapTypeId: "terrain",
  zoom: 16,
}

const renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
  position: {
    lat: 40.17516942299981,
    lng: 44.50979995368238, },
  map,
  });
  return marker;
 };

const GoogleMap = () => (
  <Box
    w="100%"
    maxW="600px"
    h="300px">
  <a
    target="_blank"
    href="http://maps.google.com/maps?daddr=40.17516942299981,44.50979995368238&mode=d">
    <Map
      bootstrapURLKeys={{ key: 'AIzaSyCAv4PXZT-SNvNVPrAYZi_YgoSMrX1ESrY' }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    />
  </a>
  </Box>
)

export default GoogleMap