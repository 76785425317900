import React from "react";
import { Box, Text, Heading, Flex, Container, VStack } from "@chakra-ui/react";
import {Link} from "gatsby";
import RichText from "../PrismicRichText";
import FinancialOrganizations from "./FinancialOrganizations";
const Banks = React.forwardRef(({ data }, ref ) => {
  const [name, setName] = React.useState('')
  const [organization, setOrganization] = React.useState('')
  const getName = name => setName(name);

  const filterOrganizations = (name) => {
      const organization = data.financialOrganizations.find(item => item.title === name);
      setOrganization(organization)
  }

  React.useEffect(() => {
      filterOrganizations(name)
  }, [name])
  return (
    <Box w="full" ref={ref}>
      <Heading as="h2" fontSize="2xl" textAlign="center" mb="1.875rem">
          {data.bankTitle}
      </Heading>
      <Box bg="green.200" >
        <Container px="5" maxW="7xl">
          <Flex
            py="1.875rem"
            direction={{ base: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <VStack
              mb={{ base: "1.875rem", sm: "0" }}
              maxW="439px"
              spacing="2"
              alignItems="flex-start"
            >
                <FinancialOrganizations data={data.financialOrganizations} getName={getName} />
            </VStack>
            <Box
              h="480px"
              maxW="528px"
              minW="400px"
              overflowY="auto"
              bg="white"
              fontSize="sm"
              p="2rem"
              borderRadius="16px"
              as="b"
            >
              {organization && organization.items?.map((item, idx) => (
                  <Text key={`bank-${item.name}-${idx}`} as={Link} to={`${item.href}`} target="_blank" lineHeight="30px" color="black" _hover={{ textDecoration: "underline", color: "green.200" }}>
                      <RichText text={item.name} />
                  </Text>
              ))}
            </Box>
          </Flex>
        </Container>
      </Box>
      <Container maxW="7xl" py="3.75rem">
        <Box fontSize="sm" color="grey.100">
          <RichText text={data.bankDescription}/>
        </Box>

      </Container>
    </Box>
  );
});

export default Banks;
