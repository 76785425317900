import * as React from 'react'
import { Container, Heading, Stack, Button } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import ContactDescription from '../../components/contactUs/ContactDescription'
import ContactForm from '../../components/contactUs/ContactForm'
import useSendEmail from '../../hooks/useSendEmail'
import usePrismicContactUs from "../../hooks/usePrismicContactUs";

const AboutUs = () => {
  const { language }= useTranslation()
  const data = usePrismicContactUs()
    const { mutate } = useSendEmail()

    const sendEmail = () => {
      mutate({
        text: 'true'
      },
      {
        onSuccess: () => console.log('success'),
        onError: (e) => console.log(e),
      })
    }
    return(
    <Main>

      <Container
        px="5"
        maxW="7xl"
        pb="6.25rem">
        <Seo
          title={data.navText}
          description={data.title}
          link={`https://www.fsm.am/${language}/contact-us`}
        />
        <SmallNav>
          {data.navText}
        </SmallNav>
        <Heading
          as="h1"
          fontSize={{base: '2xl', sm: "3xl"}}
          fontWeight="900"
          mt={{base: '3', sm: "5"}}
          mb="10">
         {data.title}
        </Heading>
        <Stack
          spacing="5"
          direction={{base: 'column', md: 'row' }}
          justifyContent="space-between">
          <ContactDescription data={data} />
          <ContactForm data={data} />
        </Stack>
      </Container>
    </Main>
  )
}

export default AboutUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`