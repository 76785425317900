import React from "react";
import {Box} from "@chakra-ui/react";
import {Link} from "gatsby-plugin-react-i18next";

const FinancialOrganizations = ({ data, getName }) => {
    const [name, setName] = React.useState('')
    React.useEffect(() => {
        const filterName = name ? name : data[0].title;
        setName(filterName);
        getName(filterName)
    }, [name]);
    
    return (
        <div>
            {data?.map((item, idx) => {
                if(item.link !== null) {
                    return (
                        <Box key={`name-${item.title}-${idx}`} mt={2} mb={2}  as={Link} to={`${item.link}`} target="_blank"
                             color="white" cursor="pointer" _hover={{ textDecoration: "underline" }}>
                            {item.title}
                        </Box>
                    )
                }
                return (
                    <Box key={`name-${item.title}-${idx}`}
                         mt={2} cursor="pointer"
                         fontSize={name === item.title ? "lg" : "md"}
                         color={name === item.title ? "black" : "white"}
                         backgroundColor={name === item.title ? "white" : ""}
                         padding={name === item.title ? "8px" : ""}
                         borderRadius={name === item.title ? "8px" : ""}
                         onClick={() => setName(item.title)}>
                        {item.title}
                    </Box>
                )
            })}
        </div>
    )
}

export default FinancialOrganizations