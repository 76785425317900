import * as React from "react";
import { Flex, Box, VStack, Text, Button } from "@chakra-ui/react";
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import GoogleMap from "../../components/googleMap";
import RichText from "../PrismicRichText";
import Banks from "../usefulInformation/Banks";

const ContactForm = ({ data }) => {
    const { language } = useI18next()
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-end"
      maxW="600px"
      flex="1">
      <Text fontSize="2xl" mb="1.81rem">
        {data.title2}
      </Text>
      <GoogleMap/>
        {language === 'hy' &&
        <VStack bg="green.200" mt="3.75rem" borderRadius="16px" pb="3.687rem" w="full">
            <Box
                fontSize="2xl"
                color="white"
                display="inline"
                mt="2.56rem"
                mb="1.875rem"
            >
               <RichText text={data.description2}/>
            </Box>
            <Link to={data.buttonLink}>
                <Button variant="green" w="220px">
                    {data.buttonText}
                </Button>
            </Link>
        </VStack>
        }
    </Flex>
  );
};
export default ContactForm;
