import { useMutation } from "react-query"
import axios from 'axios'

const useSendEmail = () => {
  const { mutate, data, isLoading, error } = useMutation((input) => {
    axios.post('https://pu3p7itxkc.execute-api.us-east-1.amazonaws.com/dev/send', {
      body: {
        test: input.text
      }
    })
  })
  return {
    mutate,
    data,
    isLoading,
    error
  }
}

export default useSendEmail
