import * as React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { Box, Text, Heading, Stack, Link } from "@chakra-ui/react";
import RichText from "../PrismicRichText";
import {Header} from "../../../.cache/fast-refresh-overlay/components/overlay";
const ContactDescription = ({ data }) => (
  <Box maxW={{base:"full",sm:"35.75rem"}} px={{base:"20px"}}>
    <Heading as="h2" fontSize="2xl" mb="4" display="inline-block">
        {data.title1}
    </Heading>
    <Box color="grey.100" fontSize="md" mb="0.5rem">
        <RichText text={data.description}/>
    </Box>
    <Text mb="0.5rem">
      {data.online}
    </Text>
    <Link href={data.submitAClaimLink} target="_blank" display="block" color="green.200" mb="0.5rem">
      {data.submitAClaim_text}
    </Link>
    <Text mb="0.5rem" mb="0.5rem">
      {data.viaEmail}
    </Text>
    <Text color="green.200" mb="0.5rem">
      <Link href={`mailto:${data.email}`} target="_blank">
          {" "}{data.email}{" "}
      </Link>
    </Text>
    <Text mb="0.5rem">
      {data.viaPhoneCall}
    </Text>
    <Text mb="0.5rem" color="grey.100">
        <Link href={`tel:${data.phone}`} color="green.200">{data.phone}</Link>, <Trans>ext.: 100</Trans>{" "}
    </Text>
    <Text mb="0.5rem">
      {data.throughSocialNetwork}
    </Text>
      {data.socialNetworks?.map((network, idx) => (
          <Link key={`network-${network.name}-${idx}`} href={network.link} target="_blank" color="green.200" display="block" mb="0.5rem">
              {network.name}
          </Link>
      ))}
    <Text mb="0.5rem">
      {data.visitingOurOffice}
    </Text>
    <Box mb="2.5rem" color="grey.100">
          <RichText text={data.description1}/>
    </Box>

    <Box
      py="2.5rem"
      borderBottom="1px solid"
      borderTop="1px solid"
      borderColor="border.100"
      mb="2.5rem"
    >
      <Text color="grey.100" fontSize="sm" mb="1.5rem">
          {data.weProvideServices_}
      </Text>
      <Stack h="2.25rem" direction="row" spacing={{base:"5px",sm:"11px"}} mb="1.5rem">
          {Object.keys(data.weekdays).map((day, idx) => (
              <Box
                  key={`week_days-${idx}`}
                  w="full"
                  borderRadius="8px"
                  bg={`${day === 'saturday' || day === 'sunday' ? '#C64545' : 'green.100'}`}
                  color="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="sm"
                  h="full"
              >
                  {data.weekdays[day]}
              </Box>
          ))}

      </Stack>
      <Box display="block" mb="1rem" color="grey.100" fontSize="sm">
          <RichText text={data.descriptionWork}/>
      </Box>
        <Heading as="h2" fontSize="xl" mb="1rem" display="inline-block">
          {data.workTitile}
      </Heading>
      <Box color="grey.100" fontSize="sm">
            <RichText text={data.contactDescription}/>
      </Box>
    </Box>
  </Box>
);

export default ContactDescription;
