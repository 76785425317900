import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicContactUs = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query PrismicContactUs {
      allPrismicContactus(filter: {tags: {in: "contact_us"}}) {
        nodes {
          lang
          data {
            buttonlink
            buttontext
            socialnetworks {
              name
              link {
                url
              }
            }
            contactdescription {
              richText
            }
            description {
              richText
            }
            description1 {
              richText
            }
            description2 {
              richText
            }
            descriptionwork {
              richText
            }
            email
            navtext
            online
            phone
            submitaclaim
            submitaclaimlink
            throughsocianetwork
            title {
              text
            }
            title1 {
              text
            }
            title2 {
              text
            }
            viaemail
            viaphonecall
            visitingouroffice
            weprovideservices_
            worktitile {
              text
            }
            weekdays {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicContactus.nodes.filter(item => item.lang === newLang)[0].data)
};

const sanitize = (response) => {
    return {
        buttonLink: response.buttonlink,
        buttonText: response.buttontext,
        contactDescription: response.contactdescription.richText,
        description: response.description.richText,
        description1: response.description1.richText,
        description2: response.description2.richText,
        descriptionWork: response.descriptionwork.richText,
        email: response.email,
        navText: response.navtext,
        online: response.online,
        phone: response.phone,
        submitAClaim_text: response.submitaclaim,
        submitAClaimLink: response.submitaclaimlink,
        throughSocialNetwork: response.throughsocianetwork,
        title: response.title.text,
        title1: response.title1.text,
        title2: response.title2.text,
        viaEmail: response.viaemail,
        viaPhoneCall: response.viaphonecall,
        visitingOurOffice: response.visitingouroffice,
        weProvideServices_: response.weprovideservices_,
        workTitile: response.worktitile.text,
        weekdays: response.weekdays[0],
        socialNetworks: response.socialnetworks.map(item => ({
            name: item.name,
            link: item.link.url
        }))
    }
}

export default usePrismicContactUs;